/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { useArchiveData, useEase } from "hooks";
import { SEO } from "components";
import { Link } from "gatsby";
import { archiveItemTypesPluralized } from "utils";
import sanityClient from "@sanity/client";
import sanityImageUrl from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import styles from "./archive.module.scss";

const client = sanityClient({
  dataset: "production",
  projectId: "jcmgoa34",
  useCdn: true,
});

const builder = sanityImageUrl(client);

const ArchiveIndexPage: React.FC = () => {
  const { archiveData, archiveYears } = useArchiveData();

  const { outQuad } = useEase();
  const getDelay = (a: number, b: number): string =>
    `${150 + 400 * outQuad(a) + 125 * outQuad(b)}ms`;

  return (
    <main className={styles.main}>
      <SEO title="Archive" />
      <ol className={styles.yearList}>
        {archiveYears.map((year, yearIndex) => (
          <li key={year} className={styles.yearListItem}>
            <h1 className={styles.yearLabel}>{year}</h1>
            <ol className={styles.archiveList}>
              {archiveData[year].map((archiveItem, itemIndex) => (
                <li
                  key={archiveItem.id}
                  className={styles.archiveListItem}
                  style={{
                    animationDelay: getDelay(
                      yearIndex / archiveYears.length,
                      itemIndex / archiveData[year].length
                    ),
                  }}
                >
                  <p className={styles.itemType}>
                    <Link
                      className={styles.typeArchiveLink}
                      to={`/archive/${
                        archiveItemTypesPluralized[archiveItem.type].lower
                      }`}
                    >
                      {archiveItem.role || archiveItem.type}
                    </Link>
                  </p>
                  {archiveItem.type === "appearance" ? (
                    archiveItem.presentation?.file.asset.url ? (
                      <a
                        href={archiveItem.presentation.file.asset.url}
                        className={styles.itemLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h2 className={styles.itemTitle}>
                          {archiveItem.information!.title}
                        </h2>
                      </a>
                    ) : (
                      <h2 className={styles.itemTitle}>
                        {archiveItem.information!.title}
                      </h2>
                    )
                  ) : archiveItem.liveSite?.useAsLink ? (
                    <a
                      className={styles.itemLink}
                      href={archiveItem.liveSite.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h2 className={styles.itemTitle}>
                        {archiveItem.information!.title}
                      </h2>
                    </a>
                  ) : (
                    <Link
                      className={styles.itemLink}
                      to={`/${archiveItem.type}/${
                        archiveItem.information!.slug!.current
                      }`}
                    >
                      <h2 className={styles.itemTitle}>
                        {archiveItem.information!.title}
                      </h2>
                    </Link>
                  )}
                  {archiveItem.image?.asset?._id && (
                    <figure className={styles.itemImage}>
                      <img
                        src={builder
                          .image({ _id: archiveItem.image.asset._id })
                          .width(500)
                          .dpr(2)
                          .url()}
                        alt=""
                      />
                    </figure>
                  )}
                  {archiveItem.type === "appearance" && (
                    <p className={styles.itemDescription}>
                      {archiveItem.location.name}
                    </p>
                  )}
                  {archiveItem.information!.shortDescription && (
                    <BlockContent
                      className={styles.itemDescription}
                      blocks={archiveItem.information!.shortDescription}
                      dataset="production"
                      projectId="jcmgoa34"
                      renderContainerOnSingleChild={true}
                      serializers={{ container: "section" }}
                    />
                  )}
                  {archiveItem.information!.tags && (
                    <ul className={styles.tagList}>
                      {archiveItem.information!.tags.map((tag) => (
                        <li
                          key={`${archiveItem.id}-${tag!.slug!.current}`}
                          className={styles.tagListItem}
                        >
                          {tag!.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </main>
  );
};

export default ArchiveIndexPage;
